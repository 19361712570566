<template>
  <UCard
    class="ring-0 shadow-none flex-1 flex flex-col min-w-1 min-h-1"
    :ui="{
      body: {
        base: 'overflow-auto min-h-96 scroll-stable',
      },
    }"
  >
    <template #header>
      <BoardSettingsHeader
        hash="settings-labels"
        title="Labels"
        :last-page="lastPage"
      />
    </template>
    <div>
      <UInput
        v-model="search"
        icon="i-heroicons-magnifying-glass-16-solid"
        placeholder="Search label"
        class="w-full"
      />
      <UPopover
        v-model:open="addLabelPopoverVisible"
        class="w-full"
        :ui="{ width: 'w-[280px]' }"
        :popper="{
          placement: 'bottom-start',
          strategy: 'fixed',
        }"
      >
        <template #panel>
          <div class="flex items-center justify-between px-4 pt-3">
            <p class="font-semibold">Create labels</p>
            <UButton
              size="xs"
              icon="i-heroicons-x-mark"
              color="gray"
              variant="ghost"
              @click="addLabelPopoverVisible = false"
            />
          </div>
          <div class="mt-5">
            <LabelForm
              ref="createForm"
              confirm-text="Add label"
              @submit="onCreateLabel"
            >
              <template #footer>
                <div class="flex justify-end items-center mt-4 gap-2">
                  <UButton
                    color="gray"
                    variant="ghost"
                    :disabled="isCreating"
                    @click="addLabelPopoverVisible = false"
                  >
                    Cancel
                  </UButton>
                  <UButton type="submit" :loading="isCreating">
                    Create
                  </UButton>
                </div>
              </template>
            </LabelForm>
          </div>
        </template>
        <UButton
          color="gray"
          variant="ghost"
          icon="i-heroicons-plus-small"
          class="mt-6 w-full"
          :ui="{ base: '!border !border-dashed !border-gray-200' }"
        >
          Create new labels
        </UButton>
      </UPopover>
    </div>
    <div class="mt-6 space-y-2">
      <template v-if="labels.length">
        <div v-for="label in labels" :key="label.id" class="flex items-center">
          <div
            :style="getColorPreset(label.color)"
            class="px-2 py-1 rounded-md text-sm text-white w-full cursor-pointer"
            @click="selectedLabel = label"
          >
            <Tooltip :text="label.name">
              <template #default="{ getTextRef }">
                <span :ref="getTextRef" class="line-clamp-1 break-all">
                  {{ label.name }}
                </span>
              </template>
            </Tooltip>
          </div>
          <UButton
            color="gray"
            variant="ghost"
            class="ml-2"
            icon="i-heroicons-pencil-square"
            @click="selectedLabel = label"
          />
          <Popover
            :open="selectedLabel?.id === label.id"
            :ui="{ width: 'w-[280px]' }"
            :popper="{
              placement: 'bottom-start',
              strategy: 'fixed',
            }"
            @close="selectedLabel = null"
          >
            <template #panel>
              <div class="flex items-center justify-between px-4 pt-3">
                <p class="font-semibold">Edit label</p>
                <UButton
                  size="xs"
                  icon="i-heroicons-x-mark"
                  color="gray"
                  variant="ghost"
                  @click="selectedLabel = null"
                />
              </div>
              <div class="mt-5">
                <LabelForm
                  ref="editForm"
                  :initial-value="{ name: label.name, color: label.color }"
                  @submit="(params: Pick<Label, 'name' | 'color'>) => onUpdateLabel(label.id, params)"
                >
                  <template #footer>
                    <div class="flex justify-end items-center mt-4 gap-2">
                      <UButton
                        color="red"
                        variant="soft"
                        size="sm"
                        :disabled="isUpdating"
                        @click="onShowDeleteModal(label)"
                      >
                        Delete
                      </UButton>
                      <UButton type="submit" :loading="isUpdating">
                        Save
                      </UButton>
                    </div>
                  </template>
                </LabelForm>
              </div>
            </template>
            <div class="hidden"></div>
          </Popover>
        </div>
      </template>
      <div v-else class="mt-16 flex justify-center flex-col gap-2">
        <!-- Empty label -->
        <UIcon
          name="i-heroicons-magnifying-glass"
          class="size-6 text-gray-500 mx-auto"
        />
        <div
          class="text-sm text-gray-500 leading-5 max-w-80 mx-auto text-center"
        >
          We couldn't find any items with that term. Create this label or try
          new term.
        </div>
      </div>
    </div>
  </UCard>
</template>

<script lang="ts" setup>
import type { Label } from '#label/types'
import { LazyDeleteModal } from '#components'

const props = defineProps<{
  parentId: string
  source: MorphSource
}>()
const route = useRoute()
const toast = useToast()
const modal = useModal()
const { preventClose } = useBoardSettingsNavigator()
const { result, refetch } = useLabelsQuery({
  parentId: props.parentId || route.query.id as string,
  source: props.source,
  loadFull: true,
})
const { createLabel } = useCreateLabel()
const { updateLabel } = useUpdateLabel()
const { deleteLabel } = useDeleteLabel()

const addLabelPopoverVisible = ref(false)
const search = ref('')
const createForm = ref()
const editForm = ref()
const selectedLabel = ref<Label | null>()
const isCreating = ref(false)
const isUpdating = ref(false)

const onShowDeleteModal = (label: Label) => {
  preventClose.value = true
  modal.open(LazyDeleteModal, {
    heading: 'Delete label?',
    content: 'This action can not be undone. Are you sure to continue?',
    confirmText: 'Delete label',
    onConfirm: async () => {
      const { error } = await deleteLabel(label.id)
      if (error) {
        return toast.add({
          title: 'Something went wrong',
          color: 'red',
        })
      }

      toast.add({
        title: 'Deleted label successfully',
      })
      modal.close()
      preventClose.value = false
    },
    onCancel: () => {
      modal.close()
      preventClose.value = false
    },
  })
}

const labels = computed(() => {
  return (
    result.value?.labels?.filter((label) =>
      (label.name ?? '').toLowerCase().includes(search.value.toLowerCase())
    ) || []
  )
})

const lastPage = computed(() =>
  props.source === 'settings-pack' ? 'settingsPack' : 'index'
)

const onCreateLabel = async (params: Pick<Label, 'name' | 'color'>) => {
  isCreating.value = true
  if (labels.value.some((label) => label.name === params.name && label.color === params.color)) {
    isCreating.value = false
    addLabelPopoverVisible.value = false
    createForm.value.resetForm()
    return
  }

  const data = await createLabel({
    ...params,
    parentId: props.parentId,
    source: props.source,
    name: params.name ?? '',
  })
  isCreating.value = false
  if (data?.error) {
    return createForm.value.setErrors(parseGqlErrors(data.error.graphQLErrors))
  }

  addLabelPopoverVisible.value = false
  createForm.value.resetForm()
  refetch()
  toast.add({
    title: 'Created label successfully',
  })
}

const onUpdateLabel = async (
  id: string,
  params: Pick<Label, 'name' | 'color'>
) => {
  isUpdating.value = true
  const data = await updateLabel({
    id,
    name: params.name,
    color: params.color,
  })
  isUpdating.value = false
  if (data?.error) {
    return editForm.value.setErrors(parseGqlErrors(data.error.graphQLErrors))
  }

  selectedLabel.value = null
  toast.add({
    title: 'Updated label successfully',
  })
}
</script>
